'use client';

import * as React from 'react';
import { useEffect } from 'react';
import { Label } from '@radix-ui/react-label';
import { useField } from 'remix-validated-form';

import { Check, ChevronDown } from '@kota/icons';
import { cn } from '@kota/utils';

import Input from './form/input';
import Popover from './popover';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from './ui/command';

type ValidationBehavior = 'onSubmit' | 'onChange' | 'onBlur';

type Props = {
  label?: string;
  labelSize?: 'sm' | 'md' | 'lg';
  name: string;
  form?: string;
  placeholder: string;
  data: { label: string | JSX.Element; value: string }[];
  command: {
    placeholder: string;
    empty: string;
  };
  helper?: string;
  disabled?: boolean;
  required?: boolean;
  optional?: boolean;
  onChange?: (value: string) => void;
  validationBehavior?: {
    initial?: ValidationBehavior;
    whenTouched?: ValidationBehavior;
    whenSubmitted?: ValidationBehavior;
  };
};

export default function Combobox({
  data,
  label,
  labelSize = 'sm',
  form,
  placeholder,
  command,
  name,
  helper,
  disabled,
  required,
  optional,
  onChange,
  validationBehavior = {
    initial: 'onSubmit',
    whenTouched: 'onSubmit',
    whenSubmitted: 'onChange',
  },
}: Props) {
  const { error, getInputProps, defaultValue, validate } = useField(name, {
    formId: form,
    validationBehavior,
  });

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue ?? '');

  useEffect(() => {
    validate();
    onChange?.(value);
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className='flex w-full flex-col space-y-2'>
      <div>
        {label && (
          <Label
            aria-labelledby={name}
            htmlFor={name}
            className={cn('group-focus-within:text-kota-primary-50 text-kota-text-90 block select-none font-medium', {
              'text-sm': labelSize === 'sm',
              'text-base': labelSize === 'md',
              'text-lg': labelSize === 'lg',
            })}
          >
            {label}
            {required ? '*' : ''}
            {optional ? <span className='text-kota-text-20 font-normal'> (optional)</span> : ''}
          </Label>
        )}

        {helper && <div className='text-kota-text-30 text-sm'>{helper}</div>}
      </div>

      <Popover
        open={open}
        variant='naked'
        disabled={disabled}
        trigger={
          <button
            type='button'
            aria-expanded={open}
            onClick={() => setOpen(!open)}
            className={cn(
              'group relative flex h-12 w-full items-center justify-between rounded-xl border bg-white px-3 py-2 text-left text-base shadow-sm',
              'focus-within:ring-kota-primary-40 hover:border-kota-primary-40 focus-within:ring-2 focus-within:ring-offset-2 focus:outline-none',
              {
                'border-kota-neutral-20': !error,
                'border-red-500': error,
                'text-kota-text-20': !value,
                'pointer-events-none opacity-60': disabled,
              },
            )}
          >
            {value ? data.find((entry) => entry.value.toLowerCase() === value.toLowerCase())?.label : placeholder}
            <ChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          </button>
        }
      >
        <Command>
          <CommandInput placeholder={command.placeholder} className='border-none focus:shadow-none focus:ring-0' />
          <CommandEmpty>{command.empty}</CommandEmpty>

          <CommandList>
            <CommandGroup>
              {data.map((entry) => (
                <CommandItem
                  className='hover:bg-kota-primary-5 cursor-pointer p-2'
                  key={entry.value}
                  value={entry.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? '' : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value.toLowerCase() === entry.value.toLowerCase() ? 'opacity-100' : 'opacity-0',
                    )}
                  />

                  {entry.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </Popover>

      <Input type='hidden' value={value} {...getInputProps()} />
    </div>
  );
}
