import type { PropsWithChildren } from 'react';

import { cn } from '@kota/utils';

type Props = PropsWithChildren & {
  size?: '14' | '16' | '18' | '20' | '24' | '28' | '32';
  weight?: 'medium' | 'semibold' | 'bold';
  family?: 'sans' | 'faktum';
  className?: string;
};

export default function Title({ children, size, weight, family = 'sans', className }: Props): JSX.Element {
  const classes = cn(
    'kota-text-50',
    {
      'text-sm': size === '14',
      'text-base': size === '16',
      'text-lg': size === '18',
      'text-xl': size === '20',
      'text-2xl': size === '24',
      'text-[28px]': size === '28',
      'text-[32px]': size === '32',
      'font-medium': weight === 'medium',
      'font-semibold': weight === 'semibold',
      'font-bold': weight === 'bold',
      'font-faktum': family === 'faktum',
    },
    className,
  );

  return <div className={classes}>{children}</div>;
}
