import { Link, type LinkProps } from '@remix-run/react';

import { XClose } from '@kota/icons';

import { Button, Progress } from '~/src/ui';

type Props = {
  title: string;
  cancel?: LinkProps;
  steps?: {
    active: number;
    total: number;
  };
};

export default function WizardHeader({ title, cancel, steps }: Props): JSX.Element {
  return (
    <div className='space-y-8'>
      <div className='flex items-center justify-between'>
        <div className='font-faktum text-2xl font-medium'>{title}</div>

        {cancel?.to && (
          <Link to={cancel.to} onClick={cancel.onClick}>
            <Button size='sm' variant='outline'>
              <XClose className='h-5 w-5' />
              <div>Cancel</div>
            </Button>
          </Link>
        )}
      </div>

      {steps && (
        <div className='flex w-full justify-between gap-3'>
          {Array.from({ length: steps.total }, (_item, index) => (
            <Progress
              className='bg-kota-neutral-10'
              key={index}
              size='sm'
              value={index <= steps.active - 1 ? 100 : 0}
            />
          ))}
        </div>
      )}
    </div>
  );
}
