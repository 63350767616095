import React, { type PropsWithChildren } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

type Props = PropsWithChildren & {
  content: string | React.ReactNode;
};

export default function Tooltip({ content, children }: Props) {
  return (
    <RadixTooltip.Provider delayDuration={300}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild type='button'>
          <button>{children}</button>
        </RadixTooltip.Trigger>

        <RadixTooltip.Portal>
          <RadixTooltip.Content
            sideOffset={5}
            className='rounded-lg bg-black px-3 pb-2 pt-1.5 text-sm font-medium text-white'
          >
            {content}
            <RadixTooltip.Arrow />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
