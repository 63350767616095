import { useState } from 'react';
import { parseDate } from '@internationalized/date';
import { format } from 'date-fns';
import type { DateValue } from 'react-aria-components';
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateInput,
  DatePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  I18nProvider,
  Label,
  Popover,
} from 'react-aria-components';
import { useField } from 'remix-validated-form';
import { v4 } from 'uuid';

import { Calendar as CalendarIcon, ChevronLeft, ChevronRight, InfoCircle } from '@kota/icons';
import type { HelperProps } from '@kota/ui';
import { FormError, Helper, Popover as KotaPopover } from '@kota/ui';
import { cn } from '@kota/utils';

type Props = {
  onChange?: (value: DateValue) => void;
  name: string;
  label: string;
  required?: boolean;
  optional?: boolean;
  disabled?: boolean;
  unavailableDates?: [DateValue[]];
  helper?: HelperProps;
  tooltip?: JSX.Element;
};

const id = `datepicker-${v4()}`;

export default function Datepicker({
  onChange,
  name,
  label,
  required,
  optional,
  disabled,
  unavailableDates,
  helper,
  tooltip,
}: Props) {
  const { error, defaultValue, getInputProps } = useField(name, {
    validationBehavior: {
      initial: 'onSubmit',
      whenTouched: 'onSubmit',
      whenSubmitted: 'onChange',
    },
  });

  const [value, setValue] = useState(
    parseDate(format(defaultValue ? new Date(defaultValue) : new Date(), 'yyyy-MM-dd')),
  );

  const isDateUnavailable = (date: DateValue) =>
    unavailableDates?.some((interval) => {
      // @ts-ignore
      return date.compare(interval[0]) >= 0 && date.compare(interval[1]) <= 0;
    }) ?? false;

  return (
    <div className='space-y-5'>
      {helper && <Helper {...helper} />}

      <I18nProvider locale='en-IE'>
        <DatePicker
          className='group'
          id={id}
          name={name}
          defaultValue={defaultValue ? value : undefined}
          isRequired={required}
          isDisabled={disabled}
          shouldForceLeadingZeros
          placeholderValue={parseDate('2024-01-31')}
          isDateUnavailable={isDateUnavailable}
          onChange={(value) => {
            if (value) {
              setValue(value);
              onChange?.(value);
            }
          }}
        >
          <div className='space-y-2'>
            <div className='flex items-center space-x-1'>
              <Label
                aria-labelledby={name}
                htmlFor={name}
                className={cn('group-focus-within:text-kota-primary-50 block select-none text-sm font-medium', {
                  'text-kota-danger-60': error,
                  'text-kota-neutral-90': !error,
                })}
              >
                <span>{label}</span>
                {required ? '*' : ''}
                {optional ? <span className='text-kota-text-20 font-normal'> (optional)</span> : ''}
              </Label>

              {tooltip && (
                <KotaPopover trigger={<InfoCircle className='text-kota-neutral-60 h-4 w-4' />}>{tooltip}</KotaPopover>
              )}
            </div>

            <Group
              className={cn(
                'hover:border-kota-primary-40 group-focus-within:ring-kota-primary-50 group-focus-within:border-kota-primary-50 flex h-12 w-full items-center space-x-3 rounded-xl border bg-white px-3 py-2 shadow-sm group-focus-within:outline-none group-focus-within:ring-2 group-focus-within:ring-offset-2',
                {
                  'border-kota-danger-60': error,
                  'border-kota-neutral-20': !error,
                },
              )}
            >
              <div className='grow'>
                <DateInput
                  className={cn('flex', {
                    'text-kota-danger-60': error,
                    'text-black': !error,
                  })}
                  {...getInputProps()}
                >
                  {(segment) => (
                    <DateSegment
                      segment={segment}
                      className={cn(
                        'focus:bg-kota-primary-50 rounded-sm px-0.5 caret-transparent outline-none focus:text-white',
                        {
                          'placeholder-shown:text-kota-danger-60': error,
                          'placeholder-shown:text-kota-text-20': !error,
                        },
                      )}
                    />
                  )}
                </DateInput>
              </div>

              <Button className='hover:bg-kota-neutral-5 rounded-md p-1.5'>
                <CalendarIcon className='h-4 w-4' />
              </Button>
            </Group>

            {error && <FormError>{error}</FormError>}
          </div>

          <Popover
            className={({ isEntering, isExiting }) =>
              cn('overflow-auto rounded-xl bg-white ring-1 ring-black/10 drop-shadow-lg', {
                'duration-200 ease-out animate-in fade-in placement-top:slide-in-from-bottom-1 placement-bottom:slide-in-from-top-1':
                  isEntering,
                'duration-150 ease-in animate-out fade-out placement-top:slide-out-to-bottom-1 placement-bottom:slide-out-to-top-1':
                  isExiting,
              })
            }
            UNSTABLE_portalContainer={document.getElementById(id)!}
          >
            <Dialog className='text-kota-text-40 p-6'>
              <Calendar value={value} onChange={setValue} focusedValue={value}>
                <header className='flex w-full items-center pb-4'>
                  <Button
                    onPress={() => setValue(parseDate(value.toString()).subtract({ months: 1 }))}
                    className='hover:bg-kota-neutral-5 rounded-md p-1.5'
                    slot='previous'
                  >
                    <ChevronLeft className='h-5 w-5' />
                  </Button>

                  <div className='flex grow justify-center'>
                    <Heading className='font-semibold'>
                      <div className='flex items-center space-x-2'>
                        <div>{format(new Date(value.toString()), 'MMMM')}</div>

                        <select
                          value={value.year}
                          className='border-kota-neutral-20 rounded-md border py-1 pl-2 pr-8 text-sm font-medium'
                          onChange={(e) => {
                            setValue(parseDate(value.toString().replace(value.year.toString(), e.target.value)));
                          }}
                        >
                          {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((year) => {
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Heading>
                  </div>

                  <Button
                    onPress={() => setValue(parseDate(value.toString()).add({ months: 1 }))}
                    className='hover:bg-kota-neutral-5 rounded-md p-1.5'
                    slot='next'
                  >
                    <ChevronRight className='h-5 w-5' />
                  </Button>
                </header>

                <CalendarGrid className='border-separate border-spacing-1' weekdayStyle='short'>
                  <CalendarGridHeader>
                    {(day) => (
                      <CalendarHeaderCell className='text-kota-text-30 text-xs font-semibold'>
                        {/* Using .slice to display `Mo` instead of `Mon`, `Tu` instead of `Tue` and so on */}
                        {day.slice(0, 2)}
                      </CalendarHeaderCell>
                    )}
                  </CalendarGridHeader>

                  <CalendarGridBody>
                    {(date) => (
                      <CalendarCell
                        date={date}
                        className='hover:bg-kota-neutral-5 pressed:bg-kota-neutral-20 selected:bg-kota-primary-50 outside-month:text-kota-neutral-20 flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg outline-none unavailable:pointer-events-none unavailable:line-through unavailable:opacity-30 focus-visible:ring selected:text-white'
                      />
                    )}
                  </CalendarGridBody>
                </CalendarGrid>
              </Calendar>
            </Dialog>
          </Popover>
        </DatePicker>
      </I18nProvider>
    </div>
  );
}
