import { useEffect, useState } from 'react';
import { Label } from '@radix-ui/react-label';
import * as Slider from '@radix-ui/react-slider';
import { useField } from 'remix-validated-form';

import { FormError } from '@kota/ui';
import { cn } from '@kota/utils';

type Props = {
  label: string;
  name: string;
  footnote?: string | JSX.Element;
  config: {
    min?: number;
    max: number;
    step?: number;
  };
  input?: {
    prefix?: string;
    suffix?: string;
  };
};

export default function SliderComponent({
  label,
  name,
  footnote,
  config: { max, min, step = 1 },
  input: { prefix, suffix } = {},
}: Props): JSX.Element {
  const { error, defaultValue, getInputProps, validate } = useField(name, {
    validationBehavior: {
      initial: 'onSubmit',
      whenTouched: 'onSubmit',
      whenSubmitted: 'onChange',
    },
  });
  const [value, setValue] = useState([defaultValue]);

  useEffect(() => {
    validate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className='space-y-6'>
      <div className='flex items-center justify-between'>
        <Label
          htmlFor={name}
          className={cn('font-semibold', {
            'text-kota-danger-60': error,
            'text-kota-neutral-90': !error,
          })}
        >
          {label}
          {footnote && <span className='text-kota-primary-50'>*</span>}
        </Label>

        <div className='relative'>
          <input
            {...getInputProps()}
            type='number'
            className={cn(
              'border-kota-neutral-20 hover:border-kota-primary-40 focus:ring-kota-primary-50 focus:border-kota-primary-50 flex w-20 items-center justify-between rounded-xl border bg-white text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
              {
                'pr-6': !!prefix,
                'border-kota-danger-60': error,
                'border-kota-neutral-20': !error,
              },
            )}
            name={name}
            value={value?.[0] || ''}
            onChange={(e) => setValue([Number(e.target.value)])}
          />

          {prefix && <div className='absolute right-[9px] top-[9px] text-sm'>{prefix}</div>}
        </div>
      </div>

      <Slider.Root
        className='relative block h-4 w-full'
        min={min}
        max={max}
        step={step}
        defaultValue={value}
        value={value}
        onValueChange={(value) => setValue(value)}
      >
        <Slider.Track className='bg-kota-neutral-10 relative block h-2 w-full rounded-full'>
          <Slider.Range className='bg-kota-primary-50 absolute h-2 rounded-full' />
        </Slider.Track>

        <Slider.Thumb
          className='bg-kota-primary-50 -mt-4 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full hover:shadow-md'
          aria-label='Volume'
        >
          <div className='h-2.5 w-2.5 rounded-full bg-white' />
        </Slider.Thumb>
      </Slider.Root>

      {error && <FormError>{error}</FormError>}

      {footnote && (
        <p className='text-kota-text-30 text-sm'>
          <span className='text-kota-primary-50'>*</span>
          {footnote}
        </p>
      )}
    </div>
  );
}
