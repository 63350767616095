import type { PropsWithChildren } from 'react';

import { AlertHexagon } from '@kota/icons';

export default function FormError({ children }: PropsWithChildren): JSX.Element {
  return (
    <div className='text-kota-danger-60 flex items-center space-x-2 text-sm'>
      <div>
        <AlertHexagon className='h-4 w-4' />
      </div>

      <div>{children}</div>
    </div>
  );
}
