import { type PropsWithChildren } from 'react';
import { Label } from '@radix-ui/react-label';
import * as Select from '@radix-ui/react-select';
import { useField } from 'remix-validated-form';

import { Check, ChevronDown } from '@kota/icons';
import type { HelperProps } from '@kota/ui';
import { FormError, Helper } from '@kota/ui';
import { cn } from '@kota/utils';

type Props = PropsWithChildren & {
  name: string;
  label: string;
  placeholder?: string;
  options: {
    value: string;
    label: string;
  }[];
  helper?: HelperProps;
  required?: boolean;
};

export default function SelectComponent({ name, label, placeholder, options, helper, required }: Props): JSX.Element {
  const { error, defaultValue, getInputProps } = useField(name, {
    validationBehavior: {
      initial: 'onSubmit',
      whenTouched: 'onSubmit',
      whenSubmitted: 'onChange',
    },
  });

  return (
    <div className='space-y-6'>
      {helper && <Helper {...helper} />}

      <div className='space-y-2'>
        <Label
          htmlFor={name}
          className={cn('text-sm font-medium', {
            'text-red-500': error,
            'text-kota-neutral-90': !error,
          })}
        >
          {label}
          {required ? '*' : ''}
        </Label>

        <Select.Root name={name} {...getInputProps()} required={required}>
          <Select.Trigger
            className={cn(
              'border-kota-neutral-20 data-[placeholder]:text-kota-text-20 hover:border-kota-primary-40 focus:ring-kota-primary-50 focus:border-kota-primary-50 flex h-12 w-full items-center justify-between rounded-xl border bg-white p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
              {
                'border-red-500': error,
                'border-kota-neutral-20': !error,
              },
            )}
          >
            <Select.Value defaultValue={defaultValue} placeholder={placeholder} />

            <Select.Icon>
              <ChevronDown className='h-5 w-5' />
            </Select.Icon>
          </Select.Trigger>

          {error && <FormError>{error}</FormError>}

          <Select.Portal>
            <Select.Content>
              <Select.ScrollUpButton />

              <Select.Viewport className='top-12 h-full w-full rounded-xl border bg-white p-1 shadow-lg'>
                {options.map(({ value, label }) => {
                  return (
                    <Select.Item
                      key={value}
                      value={value}
                      className='hover:bg-kota-neutral-5 hover:text-kota-primary-60 flex cursor-pointer items-center justify-between rounded-lg px-4 py-2'
                    >
                      <Select.ItemText>{label}</Select.ItemText>

                      <Select.ItemIndicator>
                        <Check className='h-5 w-5' />
                      </Select.ItemIndicator>
                    </Select.Item>
                  );
                })}
              </Select.Viewport>

              <Select.ScrollDownButton />
              <Select.Arrow />
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>
    </div>
  );
}
